@import "../variables";

.sidebarArea {
  flex-shrink: 0;
  width: $sidebarWidth;
  background-color: var(--bg-color);
  color: var(--text-color);
  .sidenav-bg:hover {
    opacity: 0.8;
  }
  .sidenav-selected{
    //@include gradient(to left, $list1);
    background-color: $green;
    color: $white;
    border-left: 3px solid $dgreen;
  }
  .sidenav-bg:active {
    opacity: 0.5;
  }
  .sidebarNav-dropdown{
    background-color: var(--bg-color-contrast2);
    border-left: 3px solid var(--border-color);
  }
  .sidenav-dropdown-selected {
    color: $white;
    background-color: var(--bg-color-contrast3);
    border-left: 3px solid var(--border-color2);
  }
}

@include media-breakpoint-down(lg) {
  .sidebarArea {
    position: fixed;
    height: 100%;
    overflow: auto;
    z-index: 1;
    margin-left: -$sidebarWidth;
    transition: 0.2s ease-in;
    &.showSidebar {
      margin-left: 0px;
    }
  }
}

