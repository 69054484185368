@import "variables";

@import "~bootstrap/scss/bootstrap";
@import "~bootstrap-icons/font/bootstrap-icons.scss";
@import "./layout/sidebar";
@import "./layout/container";
@import "./custom";
@import "./layout/amplifyoveride";


/*******************/
// General Styles
/********************/
.text {
    color: var(--text-color)!important;
}

.contrast-bg {
    background-color: var(--bg-color-contrast1);
}

.contrast-bg2 {
    background-color: var(--bg-color-contrast2);
}

.contrast-bg3 {
    background-color: var(--bg-color-contrast3);
}

.contrast-dropdown {
    background-color: var(--bg-color-contrast4);
    border-color: $primary!important;
}

.transitionDiv {
    // background: red;
    height: 100%;
    // width: 200px;
    margin: 0 auto;
  }
  
  @keyframes inAnimation {
    0% {
      opacity: 0;
      visibility: hidden;
      height: 0%;
    }
    100% {
      opacity: 1;
      visibility: visible;
      height: 100%;
    }
  }
  
  @keyframes outAnimation {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
      visibility: hidden;
    }
  }

.text-gradient {
    @include gradient(to left, $list1);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

/*******************/
// Style Overides
/********************/
body {
    background-color: var(--bg-color);
    color: var(--text-color);
    transition: background-color 0.5s ease;
  }

table {
    background-color: var(--bg-color-contrast1)!important;
    color: var(--text-color)!important;
}

input, select, textarea{
    color: var(--text-color)!important;
}

textarea:focus, input:focus {
    color: var(--text-color)!important;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: var(--text-color-muted)!important;
    opacity: 1; /* Firefox */
  }
  
  :-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: var(--text-color-muted)!important;
  }
  
  ::-ms-input-placeholder { /* Microsoft Edge */
    color: var(--text-color-muted)!important;
  }

.breadcrumb-item::before{
    color: var(--text-color)!important;
}

.card {
    background-color: var(--bg-color-contrast1)!important;
    color: var(--text-color)!important;
}

.list-group-item,
.list-group {
    background-color: var(--bg-color-contrast1)!important;
    color: var(--text-color)!important;
}

.text-muted {
    color: var(--text-color-muted)!important;
}

.table {
    background-color: var(--bg-color-contrast1)!important;
    color: var(--text-color)!important;
}

.form-select,
.form-control {
    background-color: var(--bg-color)!important;
    color: var(--text-color)!important;
    border: 0px solid;
}

.apexcharts-canvas > svg {
    background-color: transparent !important;
}

.pagination,
.page-link,
.page-item
 {
    background-color: var(--bg-color-contrast1);
    color: var(--text-color);
    .active {
        background-color: $primary!important;
        color: $white!important;
        :hover {
            background-color: $primary!important;
            color: $white!important;
            opacity: 0.8;
        }
        :focus {
            background-color: $primary!important;
            color: $white!important;
        }
    }
    .disabled {
        background-color: var(--bg-color-contrast2)!important;
    }
    .selected {
        background-color: var(--bg-color-contrast1);
        color: var(--text-color);
        opacity: 0.8;
    }
    :focus {
        background-color: var(--bg-color-contrast1);
        color: var(--text-color);
    }
    :hover {
        background-color: var(--bg-color-contrast1);
        color: var(--text-color);
        opacity: 0.8;
    }
}

.dropdown-item,
.dropdown-menu {
    background-color: var(--bg-color-contrast1)!important;
    color: var(--text-color)!important;
    .dropdown-item:hover {
        opacity: 0.8;
    }
    .dropdown-item:active {
        opacity: 0.5;
    }
}
.dropdown-header {
    color: var(--text-color-muted);
    opacity: 0.8;
}

.accordion,
.accordion-item {
    background-color: var(--bg-color-contrast1);
    color: var(--text-color);
}
.accordion-button:not(.collapsed){
    background-color: $primary;
    color: $white;
    
}
.accordion-button {
    background-color: var(--bg-color-contrast1);
    color: var(--text-color);
    
}

.nav-tabs {
    border: 0px solid;
}

.nav-tabs .nav-link {
    margin-bottom: 0!important;
    height: 100%!important;
}

.nav-tabs .nav-item:not(.active) {
    border-bottom: 3px solid var(--bg-color-contrast2);
}
.nav-tabs .nav-item:hover:not(.active) {
    border-bottom: 3px solid var(--bg-color-contrast3);
}
.nav-tabs .nav-item:focus:not(.active) {
    border-bottom: 3px solid var(--bg-color-contrast3);
}
.nav-tabs .nav-item:focus .active {
    border-bottom: 3px solid var(--bg-color-contrast3);
}
.nav-tabs .nav-item .active {
    border-bottom: 3px solid $dgreen!important;
}

.nav-tabs .nav-link.active {
    background-color: $primary!important;
    color: $white!important;
    border: 0px solid;
}


.nav-tabs .nav-link:hover:not(.active) {
    background-color: var(--bg-color-contrast2);
    border: 0px solid;
}
.nav-tabs .nav-item .active:hover {
    opacity: 0.8;
}

.card-top-notrounded {
    border-radius: 0 0 0.3rem 0.3rem;
}

.modal-content {
    background-color: var(--bg-color-contrast1);
}

a {
    color: var(--text-color);
    font-weight: bolder;
}
a:hover {
    color: $secondary;
}

hr.thick-jumbotron {
    background-color: $primary;
    height: 5px;
    width: 35px;
    border: 0;
    opacity: 100;
    margin-top: -0.1rem;
}

hr.heading {
    background-color: $primary;
    height: 3px;
    width: 5rem;
    border: 0;
    opacity: 100;
    margin-top: -0.25rem;
}

hr.thick-secondary {
    background-color: $secondary;
    height: 3.25px;
    width: 35px;
    border: 0;
    opacity: 100;
    margin-top: -0.25rem;
}

.alert {
    border: 0;
}

.alert-primary {
    color: $white;
    background-color: $primary;
}
.alert-secondary {
    color: $white;
    background-color: $secondary;
}
.alert-info {
    color: $white;
    background-color: $info;
}
.alert-danger {
    color: $white;
    background-color: $danger;
}
.alert-success {
    color: $white;
    background-color: $success;
}
.alert-warning {
    color: $white;
    background-color: $warning;
}

i.primary {
    color: $primary;
}
i.info {
    color: $info;
}
i.danger {
    color: $danger;
}
i.success {
    color: $success;
}
i.warning {
    color: $warning;
}

.text-primary {
    color: $primary;
}
.text-secondary {
    color: $secondary;
}

//https://codepen.io/sosuke/pen/Pjoqqp - filter colour gen
input[type=date]::-webkit-calendar-picker-indicator {
	filter: invert(76%) sepia(34%) saturate(784%) hue-rotate(31deg) brightness(78%) contrast(90%);
    cursor: pointer;
}
input[type=time]::-webkit-calendar-picker-indicator {
	filter: invert(76%) sepia(34%) saturate(784%) hue-rotate(31deg) brightness(88%) contrast(90%);
    cursor: pointer;
}
input[type=search]::-webkit-search-cancel-button {
	filter: invert(76%) sepia(34%) saturate(784%) hue-rotate(31deg) brightness(78%) contrast(90%);
    cursor: pointer;
}

select option:checked {
    background: var(--bg-color-contrast2)
}

.audio-play-button {
    background-color: #f1f3f4;
    color: #a8aaaa;
}

.audio-play-button-icon {
    font-size: 1rem;
    color: #000
}

.colored-square {
    display: flex;
    background: var(--bg-color-contrast1);
    width: 3rem;
    height: 3rem;
    align-items: center;
    justify-content: center;
}

.custom-nav:hover {
    opacity: 0.8;
}
.custom-nav:active {
    opacity: 0.5;
}

.custom-nav-selected {
    font-weight: bold;
    color: $primary;
    border-bottom: solid 3px $primary;
    padding-bottom: 0.5rem;
}

.vertical-hr {
    border: none;
    border-left: var(--bs-border-width) solid;
    color: inherit;
    height: 50px; /* Adjust the height as needed */
    margin: 0 10px; /* Adjust the margin as needed */
  }

  .accent {
    accent-color: $primary;
}

.circle {
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    background-color: #3498db;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 18px;
    font-weight: bold;
  }

/*****************************************************
User Drop Down List
*****************************************************/
.user-select__menu {
    margin-top: 0.1rem!important;
    border-radius: 0!important;
  }
  
  .user-select__control {
    border: 0!important;
  }
  
  .user-select__control--is-focused {
    box-shadow: 0 0 0 0.25rem rgba(152, 193, 60, 0.25)!important;
  }
  